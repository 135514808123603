import { defineStore } from 'pinia'
import type { Category } from '~/composables/useMenuModel'

export const useCategoriesStore = defineStore('categories', () => {
  const categories = ref<Category[]>([])

  const getCategories = computed(() => categories.value)

  function setCategories(cats: Category[] = []) {
    categories.value = cats
  }

  return {
    categories: getCategories,
    setCategories
  }
})
