<template>
  <div class="flex flex-wrap justify-center items-center">
    <div
      v-for="(announcement) in ads"
      :key="`announcement-Items-${announcement.id}`"
      class=" w-full md:w-1/2 px-2 pb-2"
    >
      <img
        class="aspect-[2] object-cover cursor-pointer"
        :src="announcement.image"
        @click="goToRelatedAd(announcement)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Ad } from '~~/composables/useMenuModel'
const { goToRelatedAd } = useAnnouncementStore()
defineProps<{
  ads?: Ad[]
}>()

</script>
