<template>
  <CustomHeader class="min-h-screen">
    <template #header>
      <div class="h-full w-full relative overflow-hidden">
        <div
          class="absolute -translate-y-1/2 translate-x-1/2 top-1/2 right-1/2"
        >
          <LayoutNavbarSearchField class="w-40" bg-color="bg-bg_color px-2" />
        </div>
        <LayoutNavbarPlatformNotification
          class="absolute -translate-y-1/2 top-1/2 rtl:left-2 ltr:right-2"
        />
      </div>
    </template>
    <template #body>
      <div class="mevcut-container min-h-screen mt-5">
        <HomeAdMainSwiper
          :ads="{
            error: getErrors,
            items: getAnnouncements?.primaryShopping
          }"
        />
        <HomeNavbarStoreTypes />
        <HomeSwiperMaterials
          :title="t('offers')"
          key-cache="offers"
          :view-more="{
            path: '/offers'
          }"
          :fetch-params="{ offersOnly: true }"
        />
        <LazyHomeAdSecondarySwiper
          :ads="{
            error: getErrors,
            items: getAnnouncements?.secondaryShopping
          }"
        />
        <LazyHomeSwiperMaterials
          key-cache="the_most_demanded"
          :title="t('the_most_demanded')"
          route="/agent/get-top-material-for-each-category"
        />
        <LazyHomeOffersGroupSlider class="mb-5" :enable-cache="true" />

        <LazyHomeSwiperMaterials
          key-cache="most_watched"
          :title="t('most_watched')"
          :view-more="{
            path: '/materials',
            query: { order_by_view_number: 1 }
          }"
          :fetch-params="{ order_by_view_number: 1 }"
        />
        <LazyHomeAdAcrossMaterials
          v-if="getAnnouncements.crossMaterialShopping?.length"
          :ads="getAnnouncements.crossMaterialShopping[0]"
        />
        <LazyHomeGridMaterials :initial-limit="50">
          <template #after-first-twenty-materials>
            <HomeSwiperMaterials
              key-cache="featured_materials"
              :title="t('featured_materials')"
              :view-more="{
                path: '/materials',
                query: { is_featured: true }
              }"
              :fetch-params="{ is_featured: true }"
            />
            <HomeAdAcrossMaterials
              v-if="
                getAnnouncements.crossMaterialShopping?.length &&
                  getAnnouncements.crossMaterialShopping?.length > 1
              "
              :ads="getAnnouncements.crossMaterialShopping[1]"
            />
          </template>
          <template #after-second-twenty-materials>
            <HomeSwiperMaterials
              :title="t('Recently_added')"
              key-cache="Recently_added"
              :view-more="{
                path: '/materials',
                query: { order_by_latest: 1 }
              }"
              :fetch-params="{ order_by_latest: 1 }"
            />
            <HomeAdAcrossMaterials
              v-if="
                getAnnouncements.crossMaterialShopping?.length &&
                  getAnnouncements.crossMaterialShopping?.length > 2
              "
              :ads="getAnnouncements.crossMaterialShopping[2]"
            />
          </template>
        </LazyHomeGridMaterials>
      </div>
    </template>
  </CustomHeader>
</template>

<script setup lang="ts">
const { url } = useDomainHost()

const { t } = useI18n()
const { getErrors, getAnnouncements } = useAnnouncementStore()
useServerSeoMeta(
  useOgMeta(
    url,
    t('home'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('home'),
  link: [useSeoCanonical(url + '/')],
  script: [useSeoBreadcrumb([useSeoBreadcrumbItem(1, t('home'), url)])]
})
</script>
