<template>
  <div class="bg-bg_color pt-4">
    <div
      v-if="storeTypesParentsLocal?.length"
      class="flex flex-wrap justify-center"
    >
      <div
        v-for="(item, index) in storeTypesParentsLocal"
        :key="`navbar-menu-item-${index}`"
        class="text-sm text-black cursor-pointer px-2 mb-3 w-1/5 sm:w-[6.7rem] md:w-[7.5rem] lg:w-[7.5rem]"
        @click="goToType(index)"
      >
        <div
          class="p-3  rounded-md mb-1 aspect-square w-full"
          :class="[
            activeIndex(index)
              ? 'bg-button_color'
              : 'bg-nav_color bg-opacity-20    hover:bg-button_color'
          ]"
        >
          <NuxtImg
            provider="cacheIpx"
            preset="modified"
            loading="lazy"
            :src="item.image"
            :alt="item.name"
            class="mx-auto select-none object-cover w-full h-full"
          />
        </div>
        <div
          class="w-full text-center font-medium text-[0.68rem] sm:text-[0.73rem] md:text-[0.78rem] lg:text-[0.83rem]"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCategoriesStore } from '~/store/categories'
import { PatternStoreType } from '~~/composables/useMenuModel'
const props = withDefaults(
  defineProps<{
    pattern?: PatternStoreType
  }>(),
  {
    pattern: PatternStoreType.Shipping

  }
)
const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()

const { storeTypesParents } = useStoreTypes(props.pattern)
function initCategoriesStore() {
  const type = storeTypesParents.value?.find(
    (el) => el.id.toString() === route.query.store_type_id
  )

  useCategoriesStore().setCategories(type?.parent_categories || [])
}

initCategoriesStore()
const storeTypesParentsLocal = computed(() =>
  storeTypesParents.value.slice(0, 10)
)
function activeIndex(index: number) {
  const typeId = storeTypesParentsLocal.value && storeTypesParentsLocal.value[index]?.id

  // eslint-disable-next-line eqeqeq
  return typeId == route.query.store_type_id
}

function goToType(index: number) {
  const type = storeTypesParentsLocal.value ? storeTypesParentsLocal.value[index] : null

  useCategoriesStore().setCategories(type?.parent_categories || [])

  router.push(
    localePath({
      name: 'store-types',
      query: {
        store_type_id: type?.id
      }
    })
  )
}
</script>

<style scoped>
.justify-evenly > .store-type-list {
  @apply m-4;
}

.justify-start > .store-type-list {
  @apply my-4 mx-8;
}
</style>
